$font-family-heading: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 0.813rem;

// GLOBAL COLORS
$teste: rgba(35, 123, 113, 0.8);
/* 
        borderColor: "rgba(75, 210, 192, 0.8)",
        pointBackgroundColor : "rgba(75, 210, 200)",
        pointBorderColor : "rgba(75, 210, 170)",
        pointHoverBackgroundColor: "rgba(75, 210, 192, 0.8)", */
$tela-fundo: rgba(22, 25, 49);
$cards: rgb(44, 54, 85, 0.2);
$borda-cards: rgb(44, 54, 85, 0.7);
$letra-cards: rgb(204, 204, 204);
$linha-grafico: rgb(17, 141, 255);

$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$teladefundo: rgba(22, 25, 49);

$blue: #000c8f !default;
$indigo: #6610f2 !default;
$pink: #e83e8c !default;
$red: #f50000 !default;
$orange: #FF9B3E !default;
$yellow: #F7CD60 !default;
$green: #69C669 !default;
$teal: #20c997 !default;
$cyan: #9c27b0 !default;
$purple: #41228e !default;
$purpleDark: #2e1865 !default;
$purpleLight: #542cb7 !default;
$purpleLighten: #6d44d2 !default;

$branded1: #103c89;
$branded2: #f50000;
$branded3: #94cef2;
$branded4: #262626;
$branded5: #95aabf;
$branded6: #202B38;

$brand: #666666;

$dark: #3F3F3F;
$dark1: #262626;
$dark2: #3F3F3F;
$dark3: #666666;

$grey: #AEB0B2;
$grey1: #AEB0B2;
$grey2: #d9d9d9;
$grey3: #f7f7f7;
$grey4: #ecebe9;
$grey5: #d4d4d4;
$grey6: #B4AEA6;

$backgroun-base: #e6e6e6;

$primary-base: $branded1;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: lighten(desaturate($primary-base, 40), 4%) !default;
$gray-700: darken(desaturate($primary-base, 40), 0%) !default;
$gray-800: darken(desaturate($primary-base, 40), 8%) !default;
$gray-900: darken(desaturate($primary-base, 40), 12%) !default;
$black: darken($primary-base, 60%) !default;

$primary: $branded1 !default;
$secondary: $branded2 !default;
$success: $primary !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-900 !default;
$background: $backgroun-base !default;
$foreground: $gray-900 !default;
$heading: darken($foreground, 8%);

$colors: (
	'blue': $blue,
	'indigo': $indigo,
	'purple': $purple,
	'pink': $pink,
	'red': $red,
	'orange': $orange,
	'yellow': $yellow,
	'green': $green,
	'teal': $teal,
	'cyan': $cyan,
	'white': $white,
	'gray': $gray-600,
	'gray-dark': $gray-800
) !default;

$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
	gray-100: $gray-100,
	gray-200: $gray-200,
	gray-300: $gray-300,
	gray-400: $gray-400,
	dark: $dark
) !default;

/**
BUTTONS
*/
$btn-primary-color: $white;
$btn-outline-secondary-color-hover: $branded2;
$btn-cancel-color: $grey6;
$btn-cancel-background-color: $grey4;
$btn-cancel-background-color-hover: $grey5;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1170px
) !default;

// LAYOUT VARS
$topbar-height: 60px;
$topbar-height-mobile: 70px;

// sidebar background
$sidebar-width: 260px;
$sidebar-min-width: 62px;
$sidebar-box-shadow: 0 2px 8px 0 rgb(32, 35, 64);
$sidebar-background: $grey3;
$sidebar-background-hover: #f2f2f2;
$sidebar-background-active: $primary;
$sidebar-border-radius: 6px;

// sidebar color text
$sidebar-text-color: $primary;
$sidebar-text-active-color: $white;
$sidebar-text-hover-color: $primary;

// sidebar color icon
$sidebar-icon-color: $primary;
$sidebar-icon-active-color: $white;
$sidebar-icon-hover-color: $primary;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/_breakpoints';
