@import 'variables';

:root {
	--mdc-outlined-text-field-error-focus-outline-color: #e04b4b;
	--mdc-outlined-text-field-error-hover-outline-color: #e04b4b;
	--mdc-outlined-text-field-error-outline-color: #e04b4b;
	--mdc-outlined-text-field-error-label-text-color: #e04b4b;
	--mdc-outlined-text-field-error-focus-label-text-color: #e04b4b;
	--mdc-outlined-text-field-focus-label-text-color: #3c6bb4;
	--mdc-outlined-text-field-focus-outline-color: #2e5fab;
	.mat-mdc-card {
		border-radius: 10px;
	}
	--mat-datepicker-calendar-container-background-color: #1d2440
}

.mat-mdc-progress-spinner {
	--mdc-circular-progress-active-indicator-color: #7e8f97!important;
}

.mat-mdc-card {
	--mdc-elevated-card-container-color: #22224c !important;
}


body {
	color: #0d47a1;
}

html,
body {
	height: 100%;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.3px;
	line-height: 1.6;
	background: $white;
	font-family: 'Open Sans', sans-serif;

	scroll-behavior: smooth;
}

[tabindex='-1']:focus {
	outline: none;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	height: 0;
}

select,
button,
textarea,
input {
	vertical-align: baseline;
}
div {
	box-sizing: border-box;
}
html,
body {
	&[dir='rtl'],
	&[dir='ltr'] {
		unicode-bidi: embed;
	}
}
bdo[dir='rtl'] {
	direction: rtl;
	unicode-bidi: bidi-override;
}
bdo[dir='ltr'] {
	direction: ltr;
	unicode-bidi: bidi-override;
}

img {
	max-width: 100%;
}
a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
}
blockquote {
	border-left: 2px solid $gray-200;
	padding-left: 1rem;
	margin-bottom: 1rem;
	font-size: 1.01625rem;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	outline: none;
}
.o-hidden {
	overflow: hidden;
}
.separator-breadcrumb {
	margin-bottom: 2rem;
}
@for $i from 1 through 5 {
	.line-height-#{$i} {
		line-height: #{$i};
	}
}

mat-form-field {
  width: 100%;
}

.th{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.center{
  justify-content: center !important;
}

// .mat-input-element {
// 	caret-color: $primary;
// }

// .mat-form-field-appearance-outline.mat-focused.mat-form-field-outline-thick {
// 	color: white !important;
// }



// .mat-flat-button.mat-primary {
// 	background-color: $primary;
// 	color: $white;
// }