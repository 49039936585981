@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

@import 'variables';
@import 'globals';
@import 'loader';
@import 'ngx-toastr/toastr';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
// @import 'bootstrap-rtl.scss';

.material-icons {
	font-family: 'Material Icons';
}

.color-primary {
  color: $primary;
}

table {
  td {
    vertical-align: middle;
  }
}

.table-line-hover {
  transition: .3s;
  &:hover {
    background: #e9e9e9;

    td {
      background: transparent;
    }
  }
}

.column-header {
  display: block;
}

.column-header .material-icons {
  font-size: 13px; /* Tamanho fixo para os ícones */
}

.column-header.ordered .material-icons {
  margin-left: 3px; 
}

.visible-icon{
  visibility: visible;
}

.hidden-icon-slug{
  visibility: hidden;
}



/* Personaliza a barra de rolagem para todo o documento */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
  * {
    scrollbar-width: thin;
  }
}

/* Trilha da barra de rolagem (o fundo da barra) */
::-webkit-scrollbar-track {
  background: rgb(44, 54, 85, 0); /* Cor de fundo */
}

/* Alça da barra de rolagem (a parte da barra que você arrasta) */
::-webkit-scrollbar-thumb {
  border-radius: 100% / 10%;
  background: #6e6b6ba7; /* Cor da barra */
}

/* Estado de hover sobre a alça da barra de rolagem */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor da barra ao passar o mouse */
}